import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"


// import {slide as Menu} from "react-burger-menu";
// import {FaFacebook, FaGithub, FaTelegram} from "react-icons/fa";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        {/*<HamMenu>*/}
        {/*    <Menu left styles={ styles } >*/}
        {/*        <a id="home" className="menu-item" href="/">Main</a>*/}
        {/*        <br />*/}
        {/*        <a id="about" className="menu-item" href="/projects">Projects</a>*/}
        {/*        <br />*/}
        {/*        <a id="contact" className="menu-item" href="/resume/">Resume</a>*/}
        {/*        <br />*/}
        {/*        <a id="contact" className="menu-item" href="/myterest/">Draw</a>*/}
        {/*        <br />*/}
        {/*        <a id="contact" className="menu-item" href="/geoarchive/">GeoPlans</a>*/}
        {/*        <br />*/}

        {/*        <a href="https://t.me/userpav" target="_blank" rel="noopener noreferrer"><FaTelegram/></a>*/}
        {/*        <br />*/}
        {/*        <br />*/}
        {/*        <a href="https://www.facebook.com/userpav" target="_blank" rel="noopener noreferrer"><FaFacebook/></a>*/}
        {/*        <br />*/}
        {/*        <br />*/}
        {/*        <a href="https://github.com/FTi130" target="_blank" rel="noopener noreferrer"><FaGithub/></a>*/}
        {/*    </Menu>*/}
        {/*</HamMenu>*/}

      <div
        style={{
          margin: `0.75rem auto`,
            //textAlign: 'center',
          maxWidth: 1200, // 960
          padding: `0 1.0875rem 1.45rem`,

        }}
      >
        <main>{children}</main>
        <footer style={{
          marginTop: `2rem`
        }}>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
